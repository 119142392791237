import * as React from 'react';

import { DEFAULT_LOG_OUT_TEXT, CHOOSE_OPTION_TEXT } from '../constants';
import { ILoginSocialBarProps } from '../LoginSocialBar.types';
import styles from './style/LoginSocialBarNativeMenu.scss';

type ILoginSocialBarNativeMenuProps = Pick<
  ILoginSocialBarProps,
  | 'menuItems'
  | 'onLogout'
  | 'logOutText'
  | 'currentPrimaryPageHref'
  | 'navigateTo'
> & {
  ariaLabel: string;
};

const OPTION_VALUE_CHOOSE = 'choose';
const OPTION_VALUE_LOG_OUT = 'logout';

const LoginSocialBarNativeMenu: React.FC<ILoginSocialBarNativeMenuProps> = ({
  ariaLabel,
  menuItems,
  logOutText,
  currentPrimaryPageHref,
  onLogout,
  navigateTo,
}) => {
  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = event => {
    const { value } = event.target;

    if (value === OPTION_VALUE_CHOOSE) {
      return;
    }

    if (value === OPTION_VALUE_LOG_OUT) {
      onLogout();
      return;
    }

    const { link } = menuItems[parseInt(value, 10)];
    navigateTo(link);
  };

  const selectedValue = React.useMemo(() => {
    const selectedMenuItemIndex = menuItems.findIndex(
      ({ link }) => link.href && link.href === currentPrimaryPageHref,
    );
    return selectedMenuItemIndex !== -1
      ? selectedMenuItemIndex
      : OPTION_VALUE_CHOOSE;
  }, [currentPrimaryPageHref, menuItems]);

  return (
    <select
      aria-label={ariaLabel}
      className={styles.root}
      defaultValue={selectedValue}
      onChange={handleChange}
    >
      <option value={OPTION_VALUE_CHOOSE}>{CHOOSE_OPTION_TEXT}</option>
      {menuItems.map(({ label, displayCount }, index) => (
        <option key={index} value={index}>
          {label}
          {typeof displayCount === 'number' && ` (${displayCount})`}
        </option>
      ))}
      <option value={OPTION_VALUE_LOG_OUT}>
        {logOutText || DEFAULT_LOG_OUT_TEXT}
      </option>
    </select>
  );
};

export default LoginSocialBarNativeMenu;
